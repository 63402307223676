import React from 'react';
import Security from '../Security';


import DocNav from './DocNav';

const MyScan = () => {
    return ( 
        <div>
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white  text-black ">

      {/* Navigation Here */}
      <DocNav />
    
      
      <div  class="h-full ml-14 mt-14 mb-10 md:ml-64">
          {/* Main content here */}
      {/* <MainContent /> */}
      <Security />

      </div>
      
    </div>
  </div>    

  
     );
}
 
export default MyScan;