import logo from './logo.svg';
import './App.css';
import Scan from './components/Scan';
import { Routes, Route, Link } from "react-router-dom";
import Security from './components/Security';
import Nav from './components/Nav';
import Basic from './components/Basic';
import Login from './components/auth/Login';
import SignUp from './components/auth/SingUp';
import Landing from './components/auth/Landing';
import NoMatch from './components/NoMatch';
import SetKeys from './components/SetKey';
import MyScan from './components/patient/MyScan';
import Instructions from './components/Instructions';

// https://patient-test.insulin.docsun.health/11473%7CbaY0ibrYQ4euQasS1xnJwDNvwcIPOsL9W32chOb5/Tue%20May%2010%202022%2021:58:09%20GMT+0300%20(East%20Africa%20Time)/7/

function App() {
  return (
    <div>
      {/* <Scan /> */}
      {/* <Nav /> */}
      <Routes>
      {/* <Route path="/" element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/Landing' element={<Landing />} /> */}
      <Route path='/' element={<MyScan />} />
      <Route path='/instructions' element={<Instructions />} />
      <Route path='/:key/:dt/:uid' element={<SetKeys />} />
      <Route path="*" element={<NoMatch />} />
           

        {/* <Route path="/security" element={<Security />} /> */}
      </Routes>
     
    </div>
  );
}

export default App;
