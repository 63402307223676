import React, { useState } from 'react';
import HeartIcon from '../images/icons/HR.png'
import BRIcon from '../images/icons/BR.png'
import SpO2Icon from '../images/icons/Spo2.png'
import BPIcon from '../images/icons/BP.png'
import BSIcon from '../images/icons/Sugar.png'
import CLIcon from '../images/icons/Cholesterol.png'
import { Link } from 'react-router-dom';
import { basicurl } from './url';

const Instructions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState('');

    
    return ( 
    <div className='flex flex-col md:m-14'>
        <div className='flex md:flex-row flex-col md:space-x-3 md:mx-20 mx-4 space-y-5 md:space-y-0'>
            <div className='md:w-1/2 bg-gray-200 rounded-3xl p-5 flex flex-col'>
                <div className='text-2xl font-bold'>Instructions</div>
                <ul class="list-disc ml-5 font-medium space-y-2 mt-8">
                    <li>Load the page and enable the internal or external camera to scan.</li>
                    <li>Perform the scan in Ambient Lighting Condition.</li>
                    <li>Do not do the scan under too much backlight.</li>
                    <li>Adjust your face position to the camera until the green frame is steadily shown.</li>
                    <li>Please stay still and avoid unnecessary facial expression.</li>
                    <li>Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection.</li>
                    <li>The scan lasts untill the health status shows up on the screen.</li>
                </ul>
                                    

            </div>
            <div className='md:w-1/2 bg-gray-200 rounded-3xl p-5 flex flex-col md:ml-7  md:pl-20'>
                <div className='text-2xl font-bold'>
                    Bias Statistics (+/-)
                </div>
                <div className='flex flex-col space-y-3'>
                    <div className='flex flex-row'>
                        <div>
                            <img src={HeartIcon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> Heart Rate</span>: +/-5 BPM

                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div>
                            <img src={BRIcon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> Breathing Rate</span>: +/-5 RPM

                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div>
                            <img src={SpO2Icon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> SpO2:</span>: +/-2 %

                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div>
                            <img src={BPIcon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> Blood Pressure:</span>: +/-15 mmHg

                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div>
                            <img src={BSIcon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> Blood Sugar:</span>: +/-1 mmol/L (18 mg/dL)

                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div>
                            <img src={CLIcon} className="w-12" />
                            
                        </div>
                        <div className='my-auto ml-5'>
                           <span className='font-bold'> Blood Cholesterol:</span>: +/-1.2 mmol/L (22 mg/dL)

                        </div>

                    </div>

                </div>

            </div>
        </div>
        <div className='flex md:flex-row mx-auto flex-col'>

            <div className='flex flex-col w-full mx-auto items-center'>

                <div className='md:ml-0 ml-4'>
                <input

                type="checkbox"
                id="topping"
                name="topping"
                value="Paneer"
                checked={isChecked}
                onChange={()=>setIsChecked(!isChecked)}
                /><span className='font-bold ml-2'>I acknowledge that I have read and understood the above</span> 

                </div>
                {error? <>
                <div className='text-sm text-red-500'>
                    {error}
                </div>
                
                </>:<></>}
                <div className='md:ml-0 ml-4'>
                    The use is advised to use the Google Chrome browser for testing. <br />
                    The user might experience system loading as such we suggest the user refresh the system when needed.
                </div>
                <div>
                

                </div>
                   
                
            </div>
            <div className='w-1/5 items-end flex flex-col'>


                {isChecked? <>
                <a href={`${basicurl}`}>

                {/* {setError('')} */}
                    <button className='btn btn-primary bg-blue-500'>
                    <div className='flex flex-col'>
                    <i class="fas fa-arrow-right text-white text-3xl"></i>
                    {/* <span>Next</span> */}
                    </div>
                </button>
                </a>
                
                </>: <>

                
                
                <button className='btn btn-primary bg-blue-500' onClick={()=>setError('Please accept Terms and conditions')}>
                <div className='flex flex-col'>
                <i class="fas fa-arrow-right text-white text-3xl"></i>
                {/* <span>Next</span> */}
                </div>
            </button>
                

                
                
                </>}
            

            
            <span className='text-blue-500'>Next</span> 
            {/* <i class="fal fa-arrow-right"></i> */}

            </div>




        

        </div>


    </div> 
    );
}
 
export default Instructions;