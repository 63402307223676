import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const SetKeys = () => {
    let {key,dt,uid}=useParams()
    const [sucess, setSuccess]=useState(false)

    useEffect(()=>{
        sessionStorage.setItem('token', key)
        sessionStorage.setItem('userid', uid)
        setSuccess(true)

    },[])
    
    // console.log(key)
    return (<>
    {sucess?<>
        <Navigate to="/instructions" />
        {/* {window.location.href = "https://patient.stress.docsun.health/"} */}
    </>:<></>}
    
    </>  );
}
 
export default SetKeys;